<template>
  <modal-wrapper :id="id" hide-footer size="md">
    <template #title>
      {{ dataTitle }}
    </template>
    <template #body>
      <p>Di seguito un elenco non esaustivo dei servizi resi disponibili da CRP.</p>
      <div class="mt-3 accordion">
        <div class="accordion-collapse">
          <b-button
            v-b-toggle.collapse-persons
            class="w-100 btn-block text-left"
            variant="primary"
          >
            <font-awesome-icon icon="chevron-right" class="accordion__icon" />
            <span>Persone fisiche</span>
          </b-button>
        </div>
        <b-collapse id="collapse-persons">
          <b-card-body class="p-0 border">
            <b-card-text>
              <pricelist-table
                :list="productsPerson"
                requestType="pr_person"
              />
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </div>
      <div class="mt-3 accordion">
        <div class="accordion-collapse">
          <b-button
            v-b-toggle.collapse-companies
            class="w-100 btn-block text-left"
            variant="primary"
          >
            <font-awesome-icon icon="chevron-right" class="accordion__icon" />
            <span>Aziende</span>
          </b-button>
        </div>
        <b-collapse id="collapse-companies">
          <b-card-body class="p-0 border">
            <b-card-text>
              <pricelist-table
                :list="productsCompany"
                requestType="pr_company"
              />
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const PricelistTable = () => import('../pricelistTable/PricelistTable.vue');

export default {
  name: 'ModalPricelist',
  components: { PricelistTable, ModalWrapper },
  props: {
    id: String,
    modalTitle: String,
  },
  data() {
    return {
      dataTitle: this.modalTitle || 'Listino prezzi',
    };
  },
  computed: {
    productsPerson() {
      return this.$store.getters['tableUtils/getTableList']('pr_person');
    },
    productsCompany() {
      return this.$store.getters['tableUtils/getTableList']('pr_company');
    },
    descNotAvailable() {
      return 'N.A.';
    },
  },
  created() {
    this.loadProducts();
  },
  methods: {
    loadProducts() {
      const defLanguage = 'it';
      if (!this.$store.getters['tableUtils/hasTableList']('pr_person')) {
        if (!this.$store.getters['tableUtils/isLoadingTableList']('pr_person')) {
          this.$store.dispatch('tableUtils/loadTableList', {
            tableName: 'pr_person',
            language: defLanguage,
          });
        }
      }
      if (!this.$store.getters['tableUtils/hasTableList']('pr_company')) {
        if (!this.$store.getters['tableUtils/isLoadingTableList']('pr_company')) {
          this.$store.dispatch('tableUtils/loadTableList', {
            tableName: 'pr_company',
            language: defLanguage,
          });
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
